@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200&display=swap");
* {
  color: black;
  font-family: Avenir, "Inter", sans-serif;
}
body {
  background-color: #b4b8bb;
  font-family: Avenir, "Inter", sans-serif;
  font-size: 18px;
}

.titleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: bottom;
}

.titleRow h3 {
  margin-left: 40px;
  padding-top: 30px;
  padding-bottom: 10px;
  width: 80%;
}

#wallet {
  margin-bottom: 10px;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  justify-content: right;
  margin-right: auto;
}

#wallet button {
  padding: 12px;
  background-color: #7408f8;
  color: white;
  border-radius: 12px;
  border: none;
  font-weight: bold;
}
#wallet button:hover {
  cursor: pointer;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  color: white;
}

.cb {
  max-height: 95vh;
  overflow: scroll;
  display: relative;
}

.card {
  max-width: 600px;
  margin: auto;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  overflow: hidden;

  transition: 0.3s;
  opacity: 1;
  transform: translateY(0) scale(1);
}

.card h3 {
  font-size: 1.5rem;
  font-weight: 200;
}

.card .body {
  padding: 20px 40px;
  padding-top: 0px;
  background-color: #f9f9ff;
}

.card input,
.card select {
  padding: 8px 10px;
  min-width: 80%;

  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: #eee;
}

body h6 {
  font-weight: lighter;
  margin: 5px;
}

.card label {
  margin-top: 20px;
  margin-bottom: 4px;
  font-weight: 300;
  color: gray;
}

.tokenLabel {
  margin-left: 44px;
}

.hidden {
  opacity: 0;
  transform: translateY(-4px) scale(0.99);
  /* // no pointer events */
  pointer-events: none;
}

.card h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.card input {
  background-color: white;
}

.dropdown-body {
  position: absolute;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 400px;
  transform: translateY(2px);

  transition: 0.1s;
  transform-origin: 0px;

  z-index: 100;

  /* Shadow */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: scroll;

  max-height: 300px;
}

.row {
  padding: 4px 20px;
  display: flex;
  cursor: pointer;

  font-size: 16px;

  transition: 0.1s;
}

.dropdown-body .row:hover {
  background-color: #f9f9ff;
}

.dropdown-button {
  text-align: left;
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
}

.relative {
  position: relative;
}

.row .images img {
  height: 30px;
  border-radius: 99999px;
  border: 3px solid white;
}

.row .images {
  transform: translateY(2px);
}

.row .images img:not(:first-child) {
  transform: translateX(-14px);
}

.farm-input {
  z-index: 0;
  display: flex;
  flex-direction: row;
  margin-right: 0px;
}
.farm-input input {
  width: 97%;
}
.farm-input img {
  height: 34px;
  border-radius: 99999px;
  vertical-align: bottom;
}

#top-label-refresh {
  margin: 5px 0px;
  height: 31px;
  vertical-align: bottom;
  position: relative;
}
#top-label-refresh .refresh-button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
#top-label-refresh label {
  position: absolute;
  bottom: 0px;
  margin-bottom: 0px;
}
._img {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

._input {
  flex-grow: 1;
  margin-left: 10px;
}

.card footer {
  padding: 10px;
}

.statbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  font-size: 16px;
}

.statbox p {
  margin: 10px;
}

.statbox:last-child {
  background-color: #eeffee;
  border-radius: 10px;
  font-weight: 500;
}

.input {
  width: 100%;
  margin-top: 14px;
}

input:disabled {
  background-color: #eee;
  color: gray;
}

.input input {
  width: 490px;
}

.tooltip-div {
  position: absolute;
  background-color: white;
  padding: 5px 20px;
  transform: translate(15px, -20px);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 100px;
}

.question-mark {
  display: inline-block;
  border: 1px solid gray;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  color: gray;
}

.refresh-button {
  background: #edeeff;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  transform: translateY(-2px);
  cursor: pointer;
  float: right;
}

.show-button {
  display: block;
  margin: auto;
  padding: 20px 50px;
  font-size: 1.4rem;
  font-weight: bold;
  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.show-button-div {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
}

.close-button {
  background: transparent;
  border: none;
  margin-right: 20px;
  cursor: pointer;
  padding-top: 20px;
}

.logo {
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 10px;
}
